.list-grid{
	margin: 60px 0px;
	.row{
		margin-bottom: 30px;
		.inner{
			position: relative;
			overflow: hidden;
			border-radius: 30px;
			height: 360px;
			top: 0px;
			transition: .2s all ease-out;	
			&:before{
				content:'';
				@include position(absolute,0);
				background-size: cover;
				background: url('../images/material/shd-list.png')repeat-x bottom;
			}
			&:hover{
				top: 2px;
				box-shadow: 0 10px 15px -10px #000;
			}
			figure{
				height: 100%;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.text-bottom{
				@include position(absolute,null 70px 55px 70px);
				.row-tag{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 10px;
				}
				.tag{
					flex: 0 0 auto;
					max-width: 30%;
				}
				h5{
					margin-bottom: 0px;
					color: $color-initial;
					font-weight: 600;
					flex: 0 0 70%;
					max-width: 70%;
				}
				p{
					color: #D6D6D6;
					font-size: 1.5rem;
					margin-bottom: 10px;
					max-height: 62px;
					overflow: hidden;
				}
			}
		}
	}
	.button{ color: #6B6DE5; }
}
.slider-hang{
	width: 100%;
	position: relative;
	margin: 20px 0 50px;
	.slider-big{
		width: 100%;
		height: 400px;
		position: relative;
		border-radius: 20px;	
		.slick-track,.slick-list{ height: 100%; }
		figure{
			height: 100%;
			position: relative;
			border-radius: 20px;
			overflow: hidden;
			&:before{
				content:'';
				@include position(absolute, 0);
				background: url('../images/material/shd-project.png')no-repeat bottom;
				background-size: cover;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.slider-thumbnail{
		@include position(absolute,null 80px -20px 80px);
		.slick-arrow{
			background: url(../images/material/arr-right-slide.svg)no-repeat center;
			width: 17px;
			height: 30px;
			border: none;
			opacity: .7;
			margin: 0;
			font-size: 0;
			@include position(absolute, null null 30% 102%);
			z-index: 3;
			padding: 0;
			border-radius: 0;
			background-size: cover;
			&.slick-prev{
				transform: rotate(180deg);
				@include position(absolute,null 102% 30% auto);
			}
			&:hover{
				opacity: 1;
			}
		}
		.list-slider{
			margin:0 10px;
			border-radius: 10px;
			overflow: hidden;
			border: 2px solid transparent;
			&.slick-current{
				border-color: #fff;
			}
		}
		figure{
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

.slider-category{
	margin: 50px auto;
	padding: 0px 30px;
	box-sizing: border-box;
	position: relative;
	.list-slider{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 20px;
		padding-bottom: 10px;
		position: relative;
		cursor: pointer;
		&:before{
			content:'';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 0;
			height: 3px;
			background: $color-primary;
			border-radius: 20px;
			overflow: hidden;
			margin: 0 auto;
			transition: .2s all ease-out;
		}
		&:hover{
			&:before{
				width:70%;
			}
		}
		figure{
			width: 50px;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		span{
			font-size: 16px;
			font-weight: bold;
			color: #555555;
			width: 85px;
			margin-left: 15px;
			display: inline-block;
		}
	}
	.slick-track{ 
		display: flex;
		align-items: center;
	}
	.active{
		&:before{
			width:100%;
		}
	}
	.slick-arrow{
		background: url(../images/material/arr-right-blue.svg)no-repeat center;
		width: 17px;
		height: 30px;
		border: none;
		opacity: .7;
		margin: auto 0;
		font-size: 0;
		@include position(absolute, 0 0 0 null);
		z-index: 3;
		padding: 0;
		border-radius: 0;
		background-size: cover;
		&.slick-prev{
			transform: rotate(180deg);
			@include position(absolute,0 auto 0 0);
		}
		&:hover{
			opacity: 1;
		}
		&.slick-disabled{
			opacity: 0;
		}
	}
}
.box-column-categ{
	&.highlight{
		padding: 50px 0px 0px;
		.column{ margin-bottom: 0px; } 
		.text{
			p{margin-bottom: 0px;}
		}
	}
	figure{
		height: 170px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
		overflow: hidden;
		img{ transition: .2s all ease-out; }
	}
	.row{ 	text-align: center;}
	.column{ 
		margin-bottom: 30px; 
		padding: 10px 15px;
		box-sizing: border-box;
		transition: .2s all ease-out;
		position: relative;
		top: 0;
		&:hover{
			top:2px;
			box-shadow:0 10px 18px -17px #000;
			figure{
				img{ transform:scale(1.1); }
			}
		}
	}
	.text{
		h6{ 
			font-size: 1.6rem; 
			color: #111111;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}
}
.wrap-detail-category{
	margin-top: 30px;
	h4{
		color: #111111;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 20px;
	}
	.right-box{
		/*background: #F7F7F7;*/
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.button{ 
		display: block; 
		width: 100%;
		margin-top: 30px;
	}
	.spek{
		p{
			margin-bottom: 10px; 
		}
	}
}
.es-detail{
	align-items: center;
	figure{
		img{
			width: 100%;
		}
	}
}
.full-img {
	figure { margin: 40px auto; width: 60%; }
	img { height: auto; object-fit: cover; object-position: center; width: 100%; }
}
.other-produk{
	margin: 50px 0px 0;
	h6{
	 	font-size: 1.9rem; 
	 	font-weight: bold;
	 	color: #111;
	 }
	.slider-other-produk, .list-other-produk {
		text-align: center;
		position: relative;
		margin: 0 -15px;
		display: flex; flex-direction: row; flex-wrap: wrap;
		&:after{
			display: none;
			content:'';
			@include position(absolute, 0 0 0 null);
			width: 90px;
			height: 100%;
			background: url('../images/material/shadow-otherproject.png')no-repeat center;
			background-size: cover;
		}
		p{ margin-bottom: 5px; }
		figure{
			height: 140px;
			background: #F7F7F7;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px 13px;
			box-sizing: border-box;
			margin-bottom: 15px;
			overflow: hidden;
		}
		.list{
			display: block;
			margin: 0 15px 30px;
			width: calc(100% / 3 - 30px);
			.spek{
				text-align: left;
				padding: 0 20px;
				p{
					font-size: 1.4rem;
					line-height: 1.3;
					&.title{
						text-align: left;
						font-size: 1.6rem;
						font-weight: 700;
						margin-bottom: 1rem;
					}
				}
			}
		}
		.slick-arrow { background: transparent; cursor: pointer; position: absolute; top: 50%; z-index: 99; @include transform(translateY(-50%)); 
			&.slick-prev { left: -20px; @include transform(translateY(-50%) scaleX(-1));
				&:before { content: url('../images/material/arr-right-blue.svg'); position: relative; }
			}
			&.slick-next { right: -20px; 
				&:before { content: url('../images/material/arr-right-blue.svg'); position: relative; }
			}
		}
	}
}

.accordion{ margin-top: 50px;
	padding: 70px 0px;
	&.bg-gry{
		background: #F7F7F7;
	}
	.wrap-accordion{
		.list-acc{
			margin-bottom: 15px;
			padding: 30px 40px;
			background: #fff;
			border-radius: 10px;
			&:last-child{
				margin-bottom:0px;
			}
			&.act{
				.btn-acc{
					display: none;
					&.close{
						display: inline-block;
					}
				}
			}
		}
		.btn-acc{
			&.close{
				display: none;
			}
		}
		.grid-accordion{
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			h6,span{ margin-bottom: 0px; }
			h6{
				font-size: 1.9rem;
				font-weight: 600;
				color: #333333;
			}
			span{
				font-size: 1.3rem;
				color: #5C5DC4;
				font-weight: bold;
				text-transform: uppercase;
				cursor: pointer;
			}
		}
		.content-accordion{
			padding-top: 20px;
			display: none;
			p{
				margin-bottom: 0px;
			}
		}
	}
	.spek-detail{
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: space-between;
		margin: 25px 0 0;
		.list{
			flex:1 0 48%;
			max-width: 48%;
		}
	}
}