@media (min-width: 0px) and (max-width: 767px) {
	.button, button, input[type='button'], input[type='reset'], input[type='submit']{
		line-height: 5rem;
	}
	header{
		height: 60px;
		.wrapper{
			margin: 0 17px;
		}
		.logo{
			width: 100px;
			padding: 15px 30px;
			height: 100%;
			border-radius:0 0 20px 20px;
			a{
				height: auto;
			}
		}
		.burgermenu{
			width: 30px;
			height: 21px;
			span{
				border-radius: 10px;
				overflow: hidden;
			}
		}
		.right-header{
			padding: 10px 17px 20px;
			height: calc(50vh + 65px);
			.box-search{
				.input-search{
					input[type='text']{ height: 50px; }
				}
			}
			
		}
	}
	.tag{ 
		font-size: 0px; 
		width: 32px;
		height: 32px;
		padding: 10px;
		box-sizing: border-box;
		&.toll{
			&:before{
				top:0; right: 0; left: 0; bottom: 0;
				margin: auto;
				width: 16px;
				height: 11px;
				background-size: cover;
			}
		}
		&.mining{
			&:before{
				top:0; right: 0; left: 0; bottom: 0;
				margin: auto;
				width: 16px;
				height: 11px;
				background-size: cover;	
			}
		}
	}

	.banner-content{
		height: 240px;
		.text-caption{
			width: 100%;
			max-width: 80%;
			h2{
				font-size: 1.8rem;
			}
			h5{
				font-size: 1.2rem;
			}
		}
	}
	.breadcrumb{
		display: none;
	}
	.content{
		padding: 60px 0px;
		.wrapper{
			margin: 0 17px;
		}
		&.padding-topmore{
			padding-top: 80px;
		}
	}
	.list-grid{
		.row{
			.inner{
				height: 135px;
				border-radius: 15px;
				.text-bottom{
					left: 30px;
					right: 30px;
					bottom: 20px;
					p{ display: none; }
					h5{
						font-size: 1.5rem;
					}
				}
			}
		}
	}
	.result{
		h4{ font-size: 1.7rem; }
	}
	.list-result{
		.list{
			h6{ font-size: 1.4rem; }
			p{
				font-size: 1.4rem;
			}
		}
	}
	.section-center{
		.text-wrapper{
			h5{ font-size: 1.5rem; margin-bottom: 10px; }
			p{
				line-height: 2rem;
			}
		}
	}
	.text-wrapper{
		ol,ul{
			li{ font-size: 1.4rem; }
		}
		iframe{
			min-height: 250px;
		}
	}
	.text-center{
		margin: 15px auto;
	}
	.navigasi-banner{
		width: 100%;
		.wrapper{ 
			margin: 0 17px; 
			width: 700px;
			ul{
				justify-content: flex-start;
				li{
					padding-bottom: 15px;
					a{
						font-size: 1.4rem;
					}
				}
			}
		}
	}
	.images-content{
		figure{
			height: 150px;
		}
	}
	.pagging{
		a{
			&.arr{
				left: -20px;
				&.next{
					right: -20px;
				}
			}
		}
	}
	.career-accordion{
		min-height: 270px;
		.list-accordion{
			padding: 15px 20px;
		}
		.grid-accordion{
			h6{ font-size: 1.5rem; }
			.lok{ font-size: 1.1rem; }
		}
	}
	.title-sort-by{
		.row{
			flex-flow: row wrap;
			justify-content: flex-start;
			
		}
		.search,.select{
			justify-content: flex-start;
			margin-top: 10px;
		}
		h6{
			font-size: 1.5rem;
		}
	}
	.wrap-pop{
		.pop-inner{
			.box-pop{
				width: auto;
				margin: 20% 17px;
			}
			.content-pop{
				.img{
					padding: 35px;
				}
				.text{
					padding: 30px 30px 10px;
				}
			}
		}
	}
	.wrap-news{
		figure{
			border-radius: 0;
		}
		.row{
			padding-right: 0px;
			border-radius: 15px;
			overflow: hidden;
			.column{
				&.column-45{
					flex: 1 0 100%;
					max-width: 100%;
				}
				&.column-50{
					flex: 1 0 100%;
					max-width: 100%;
					margin-left: 0px;
					padding: 25px 15px;
				}
			}
		}
	}
	.form-middle{
		.row{
			.column{
				&.column-48{
					flex: 1 0 100%;
					max-width: 100%;
					margin-left: 0px;
				}
			}
		}
		input[type='text'],
		input[type='number'],
		input[type='password'],
		input[type='email'],
		input[type='search'],select,textarea{
			padding: 35px 30px 20px;
		}
	}
	footer{
		.wrapper{
			margin: 0 17px;
		}
		.center-footer{
			padding: 40px 0px;
			.row{
				flex-flow: row nowrap;
			}
			.column{
				padding: 0 1.0rem;
				margin: 5px 0px;
				&:nth-child(1){ display: none; }
				&.column-12{
					flex: 1 0 50%;
					max-width: 50%;
				}
				&.column-25{
					flex: 1 0 50%;
					max-width: 50%;
				}
				&.images-footer{
					.row{
						flex-flow: row wrap;
					}
					.column{
						display: block;
					}
				}
			}
		}
	}
}