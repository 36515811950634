@media (min-width: 0px) and (max-width: 767px) {
	
	.banner-home{
		.slider-home{
			figure{
				figcaption{
					h2{
						font-size: 2.2rem;
					}
					h5{ font-size: 1.4rem; }
				}
			}
		}
		.pagedown{
			width: 52px;
			height: 52px;
			bottom: -25px;
			span{
				width: 16px;
				height: 22px;
			}
		}
	}
	.home{
		&.aboutus{
			.text{
				flex: 1 0 80%;
				max-width: 80%;
			}
		}
		
		.wrapper{
			margin: 0 17px;
		}
		.title-icon{
			figure{
				width: 32px;
				height: 32px;
			}
			h5{ font-size: 1.3rem; }
		}
		&.aboutus{
			.logo-absolute{
				display: none;
			}
			.wrapper{
				padding-top: 60px;
				margin: 0 17px;
				.text{
					align-self: flex-start;
					flex: 1 0 70%;
					max-width: 70%;
				}
			}
			.bg-img{
				top: auto;
				bottom: 0;
				height: 70%;
				width: 45%;
				img{
					object-position: left;
				}
			}
			.object-absolute{
				width: 100px;
				height: 161px;
				bottom: -17px;
			}
		}
		&.recentproject{
			padding: 40px 0px 0px;
			&:before{
				height:195px;
			}
			.slider-recent-small{
				.slick-arrow{
					width: 10px;
					height: 18px;
				}
			}
			.slider-project-home{
				.list-slider{
					height: 137px;
					&:before{
						height:112px;
						background-position: top;
						background-size: cover;
					}
					.left-box{ display: none; }
					.right-box{
						p{ display: none; }
					}
					.column.column-85{
						flex: 1 0 100%;
						max-width: 100%;
					}
				}
				.text-bottom{
					left: 40px; right: 40px; bottom: 30px;
				}
			}
			.categ-small{
				.row{
					justify-content: center;
					text-align: center;
					.column{
						justify-content: center;
						&.column-33{
							flex: 1 0 100%;
							max-width: 100%;
						}
						figure{
							flex: 1 0 48px;
							max-width: 48px;
						}
					}
				}
			}
		}
		&.business{
			.slider-ourbusiness-mobile{
				.slick-arrow{
					top: auto;
					bottom: 25%;
				}
				.column.column-33,.column.column-34{
					flex:0 0 100%;
					max-width: 100%;
					padding:0 10px;
				}
			}
		}
		&.range{
			padding: 30px 0px;
		}
		
	}
	.page100vh{
		.text{
			left: 40px; right: 40px;
			h1{ font-size: 10rem; margin-bottom: 5px; }
			h2{ font-size: 4rem; margin-bottom: 5px; }
			h5{ font-size: 1.5rem; margin-bottom: 5px; }
			p{ font-size: 1.4rem; line-height: 2rem; }
		}
	}
}