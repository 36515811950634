@media (min-width: 1340px) {
	header{
		height: 70px;
		.logo{
			width: 200px;
			padding: 0px 30px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.wrapper{
			margin: 0 100px;
		}
	}
	.content{
		.wrapper{
			width: 1170px;
		}
	}
	.banner-content{
		figure{
			&:after{ background-position: bottom; }
		}
	}
	.landing-about{
		.row{
			.column{
				&.column-60{ 
					flex: 0 0 45%; 
					max-width: 45%;
				}
			}
		}
	}
}

@media (min-width:0px) and (max-width: 1281px) {
	header{
		.wrapper{
			width: auto;
			margin: 0 50px;
		}
	}
}
