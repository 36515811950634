.banner-home{
	height: calc(100vh - 40px);
	position: relative;
	.slider-home{
		height: 100%;
		figure{
			position: relative;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			&:before{
				content:'';
				background: url(../images/material/shd-bannerhome.png)no-repeat center;
				background-size: cover;
				z-index: 1;
				@include position(absolute,0);
			}
			figcaption{
				@include position(absolute,50% 0 null 0);
				@include transform(translateY(-50%));
				margin: auto;
				text-align: center;
				color: $color-initial;
				max-width: 700px;
				z-index: 2;
				h2{
					text-transform: uppercase;
					font-weight: bold;
				}
				h5{
					font-weight: lighter;
				}
				.button{
					transition: none;
				}	
			}
		}
		.list-slider{
			height: 100%;
		}
		.slick-list,.slick-track{ height: 100%; }
		.slick-arrow{
			background: url(../images/material/arr-right-slide.svg)no-repeat center;
			width: 33px;
			height: 58px;
			border: none;
			opacity: .3;
			margin: 0;
			font-size: 0;
			@include position(absolute, 50% 3% 0 null);
			transform: translateY(-50%);
			z-index: 3;
			padding: 0;
			border-radius: 0;
			&.slick-prev{
				transform: rotate(180deg);
				@include position(absolute,46% auto 0 3%);
			}
			&:hover{
				opacity: 1;
			}
		}
	}
	.pagedown{
		width: 80px;
		height: 80px;
		border-radius: 100%;
		margin: 0 auto;
		z-index: 2;
		background: url('../images/material/bg-scrolldown.svg')no-repeat center;
		@include position(absolute,null 0 -40px 0);
		span{
			@include position(absolute,0);
			margin: auto;
			display: block;
			width: 24px;
			height: 35px;
			opacity: .7;
			filter: blur(0.5px);
			transition: .2s all ease-out;
		}
		&:hover{
			span{
				opacity: 1;
			}
		}
	} 
}


.home{
	.wrapper{
		width: 1197px;
		margin: 0 auto;
	}
	&.aboutus{
		position: relative;
		.logo-absolute{
			z-index: -1;
			@include position(absolute,0px null null 0);
		}
		.bg-img{
			@include position(absolute,0 0 0 null);
			max-width: 45%;
			&:before{
				content:'';
				position: absolute; left: -2px; top: 0; bottom: 0;
				width: 20%; height: 100%;
				background: rgba(255,255,255,1);
				background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)));
				background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				background: -o-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.object-absolute{
			@include position(absolute,null 37% -65px null);
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.wrapper{
			width: 1179px;
			margin: 0 auto;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			position: relative;
			min-height: 700px;
		}
		.text{
			flex: 1 0 40%;
			max-width: 40%;
			align-self: center;
			h3,h1{
				font-weight: 600;
				color: #111;
				margin-bottom: 15px;
			}
			h1 {
				font-size: 3.3rem;
			}
			p{	
				color: #333; 
				line-height: 3rem;
			}
		}
	}
	.title-icon{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		h5{
			margin-bottom: 0;
			margin-left: 10px;
			font-weight: 600;
			color: #111;
			text-transform: uppercase;
		}
	}
	&.recentproject{
		position: relative;
		padding: 100px 0px 0;
		&:before{
			content:'';
			width: 100%;
			height: 420px;
			background: rgba(56,58,139,1);
			background: -moz-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(56,58,139,1)), color-stop(100%, rgba(48,29,100,1)));
			background: -webkit-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
			background: -o-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
			background: -ms-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
			background: linear-gradient(to bottom, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#383a8b', endColorstr='#301d64', GradientType=0 );
			@include position(absolute,0 0 null 0);
			z-index: -1;
		}
		.logo-absolute{
			position: absolute;
			right: 0px;
			bottom:15%;
			z-index: -1;
		}
		.categ-small{
			margin-bottom: 80px;
			.column{
				align-items: center;
				display: flex;
				color: $color-initial;
				figure{
					margin-right: 25px;
				}
				h6{
					font-size: 2.9rem;
					font-weight: bold;
					margin-bottom: 0;
				}
				p{
					color: #A8A9DD;
					display:none;
					margin-bottom: 0px;
				}
			}
		}
		.slider-recent-small{
			padding: 0 20px;
			position: relative;
			box-sizing: border-box;
			.slick-track,.slick-list{
				width: 100% !important;
				display: flex;
				justify-content: space-between;
			}
			.slick-arrow{
				font-size: 0;
				padding: 0;
				background: url(../images/material/arr-right-slide.svg) no-repeat center;
				width: 20px;
				height: 30px;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto 0;
				border: none;
				background-size: cover;
				z-index: 2;
				&.slick-prev{
					right: auto;
					left: 0;
					transform: rotate(180deg);
				}
			}
		}
		.slider-project-home{
			position: relative;
			background: #fff;
			border-radius: 30px;
			overflow: hidden;
			.text-bottom{
				color: $color-initial;
				@include position(absolute,null 130px 50px 130px);
				z-index: 1;
				.current-slider{
					font-size: 4.4rem;
					font-weight: bold;
				}
			}
			.title-slider-project{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;
				h5{
					margin-bottom: 0px;
					font-weight: 600;
				}
			}
			.list-slider{
				border-radius: 30px;
				overflow: hidden;
				position: relative;
				height: 500px;
				&:before{
					content:'';
					@include position(absolute,null 0 0 0);
					background: url('../images/material/shd-project.png')no-repeat;
					height: 400px;
				}
				figure{
					height: 100%;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.left-box{
					h5{
						font-weight: 600;
						text-transform: uppercase;
						margin-bottom: 5px;
					}
				}
				.right-box{
					p{color: #D6D6D6;}
					a{ color: #6B6DE5; }
				}

			}
			.row{
				.columns{
					span{
						display: block;
					}
				}
			}
			.slick-arrow{
				background: url(../images/material/arr-right-slide.svg)no-repeat center;
				width: 33px;
				height: 58px;
				border: none;
				opacity: .3;
				margin: 0;
				font-size: 0;
				@include position(absolute, null 3% 25% null);
				z-index: 3;
				padding: 0;
				border-radius: 0;
				&.slick-prev{
					transform: rotate(180deg);
					@include position(absolute,null auto 25% 3%);
				}
				&:hover{
					opacity: 1;
				}
			}
		}
	}
	&.business{
		position: relative;
		padding-top: 60px;
	    background: rgba(253,253,254,0.25);
		background: -moz-linear-gradient(top, rgba(253,253,254,0.25) 0%, rgba(51,40,116,0.25) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(253,253,254,0.25)), color-stop(100%, rgba(51,40,116,0.25)));
		background: -webkit-linear-gradient(top, rgba(253,253,254,0.25) 0%, rgba(51,40,116,0.25) 100%);
		background: -o-linear-gradient(top, rgba(253,253,254,0.25) 0%, rgba(51,40,116,0.25) 100%);
		background: -ms-linear-gradient(top, rgba(253,253,254,0.25) 0%, rgba(51,40,116,0.25) 100%);
		background: linear-gradient(to bottom, rgba(253,253,254,0.25) 0%, rgba(51,40,116,0.25) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdfdfe', endColorstr='#332874', GradientType=0 );
		&:before{
			content:'';
			@include position(absolute,0);
			background: url('../images/content/bg-ourbisnis.png')no-repeat bottom;
			z-index: 1;
			background-size: cover;
		}
		.wrapper{ 
			position: relative;
			z-index: 2;
		 }
		 .box-business{
		 	padding-bottom: 130px;
		 	a{
		 		position: relative;
		 		top: 0;
		 		transition: .2s all ease-out;
		 		&:hover{
		 			top:2px;
		 		}
		 	}
		 	figure{
		 		position: relative;
		 		height: 280px;
		 		overflow: hidden;
		 		border-radius: 20px;
		 		&:before{
		 			content:'';
		 			@include position(absolute,0);
		 			background: url('../images/material/shd-project.png')no-repeat bottom;
		 			background-size: cover;
		 		}
		 		img{
		 			width: 100%;
		 			height: 100%;
		 			object-fit: cover;
		 			transition: .2s all ease-out;
		 		}
		 	}
		 	figcaption{
		 		color: #FFFFFF;
		 		@include position(absolute,null 40px 40px 40px);
		 		h6{
		 			margin-bottom: 5px;
		 			font-weight: 600;
		 		}
		 		p{ 
		 			margin-bottom: 0px; 
		 		}
		 	}
		 }

		.slider-ourbusiness-mobile{
	 		.slick-list,.slick-track{
	 			display: flex;
	 			width: 100% !important;
	 		}
 			.slick-arrow{
				font-size: 0;
				padding: 0;
				background: url(../images/material/arr-right-slide.svg) no-repeat center;
				width: 20px;
				height: 30px;
				position: absolute;
				right: 20px;
				top: 0;
				bottom: 0;
				margin: auto 0;
				border: none;
				background-size: cover;
				z-index: 2;
				&.slick-prev{
					right: auto;
					left: 20px;
					transform: rotate(180deg);
				}
			}
	 	}
	}
	&.range{
		padding: 80px 0px;
	}
}