@media (min-width: 0px) and (max-width: 767px) {
	
	.landing-about{
		.row{
			.column{
				&.column-60{
					flex: 1 0 100%;
					max-width: 100%;
					padding: 40px 0 350px;
				}
			}
		}
	}
	.section-about{
		.images-absolute-right,.images-absolute-left{
			width: 100%;
			bottom: 0; left: 0; right: 0px;
			top: auto;
			display: block;
			height: 350px;
		}

	}
	.bg-gradient{
		&:before{
			height:200px;
		}
		&.about{
			&:before{
				height:100%;
			}
		}
		&.sliderhang{
			&:before{
				height: 250px;
			}
		}
	}
	.section-center{
		.wrapper{
			min-height: auto;
			padding: 15px 0px;
			&.nopadding{
				padding: 50px 0; 
			}
		}
		.text-wrapper{
			h5{ padding: 0px; }
			p{
				max-height: unset;
			}
		}
	}
	.text-wrapper{
		h5{ margin-bottom: 10px; }
		p{ font-size: 1.4rem; line-height: 2rem; }
	}
	.milestone-years{
		.list-miles{
			flex-flow: row nowrap;
			&.row{
				
			}
			h6{ font-size: 1.4rem; margin-bottom: 10px; }
			p{ font-size: 1.4rem; line-height: 2rem; }
		}
		.years{
			span{ font-size: 1.7rem; }
		}
	}
	.wrap-awards{
		.row{
			margin-bottom: 0px;
			.column {
				&.column-48,
				&.column-33 {
					flex: 1 0 100%;
					max-width: 100%;
					margin-bottom: 30px;
				}
			}
		}
	}
}