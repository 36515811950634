/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
@import "helper/sass-lib";
@import "helper/flexbox";

/* milligram
----------------------------------------------------------------------------------------------*/
@import "milligram/milligram";

/* pages
----------------------------------------------------------------------------------------------*/
@import "pages/general";
@import "pages/home";
@import "pages/about";
@import "pages/business";

/* pages responsive desktop
----------------------------------------------------------------------------------------------*/
@import "pages_desktop/general";
@import "pages_desktop/home";

/* pages responsive tablet
----------------------------------------------------------------------------------------------*/
@import "pages_tablet/general";
@import "pages_tablet/home";
@import "pages_tablet/about";
@import "pages_tablet/business";


/* pages responsive mobile
----------------------------------------------------------------------------------------------*/
@import "pages_mobile/general";
@import "pages_mobile/home";
@import "pages_mobile/about";
@import "pages_mobile/business";