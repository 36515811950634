@media (max-width: 1460px) {
	
	.home{
		&.recentproject{
			.logo-absolute{
				width: 200px;
				bottom: 25%;
			}
		}
	}
}