@media (min-width: 0px) and (max-width: 1279px) {
	.banner-home{
		height: calc(100vh - 100px);
		.slider-home{
			figure{
				figcaption{
					 h2{ font-size: 3rem; }
					 h5{ font-size: 1.9rem; }
				}
			}
		}
	}
	.home{
		.title-icon{
			h5{ font-size: 1.9rem; }
		}
		.wrapper{
			width: auto;
			margin: 0px 70px;
		}
		&.aboutus{
			.wrapper{ 
				margin: 0 70px; 
				width: auto;
				min-height: 450px;
			}
			.logo-absolute{
				width: 90px; height: 75px;
				img{
					object-position: right;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.object-absolute{
				height: 333px;
				width: 205px;
				img{
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
		}
		&.recentproject{
			&:before{
				height:320px;
			}
			.categ-small{
				margin-bottom: 40px;
				.column{
					figure{
						flex: 1 0 59px;
						max-width: 59px;
					}
					p{
						max-height: 40px;
						overflow: hidden;
					}
				}
			}
			.slider-project-home{
				.text-bottom{ 
					left: 80px; right: 80px; 
					.current-slider{
						font-size: 2.5rem;
					}
				}
				.list-slider{
					.left-box{
						h5{ font-size: 1.4rem; }
					}
				}
				.slick-arrow{
					width: 13px;
					height: 23px;
					background-size: cover;
				}
			}
		}
	}
	.bg-gradient{
		&:before{  height:350px; }
	}
	.images-content{
		figure{ height: 300px; }
	}
	footer{
		.wrapper{
			width: auto;
			margin: 0px 70px;
		}
		.bottom-footer{
			text-align: center;
			padding: 20px 0;
		}
	}
	.home{
		&.aboutus{
			.wrapper{
				margin: 0 45px;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 1023px) {
	h3{ font-size: 2rem; }
	header{
		height: 60px;
		.wrapper{
			margin: 0 45px;
		}
		.logo{
			padding: 10px 25px;
			height: 60px;
			width: 180px;
			a{
				display: block;
				width: 100%;
				height: 100%;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.banner-home{
		.slider-home{
			figure{
				figcaption{
					left: 45px; right: 45px;
					h2{
						font-size: 2.8rem;
					}
				}
			}
		}
		.pagedown{
			width: 77px;
			height: 77px;
		}
	}
	.home{
		.wrapper{
			margin: 0 45px;
		}
		.title-icon{
			figure{
				width: 40px;
				height: 40px;
			}
		}
		&.aboutus{
			.wrapper{
				margin: 0 45px;
			}
			.bg-img{
				max-width: 50%;
			}
			.object-absolute{
				right: 40%;
				bottom: -27px;
				width: 150px;
				height: 245px;
			}
			.text{
				h1 {
					font-size: 2rem;
				}
				p{ line-height: 2.5rem; }
			}
		}
		.title-icon{
			h5{ font-size: 1.7rem; }
		}
		&.recentproject{
			padding: 60px 0 0;
			&:before{
				height:250px;
			}
			.categ-small{
				margin-bottom: 25px;
				.column{
					h6{ margin-bottom: 0px; }
					p{ display: none; }
				}
			}
			.logo-absolute{
				display: none;
			}
			.text{
				align-self: center;
				h6{
					font-size: 1.6rem;
				}
			}
			.slider-recent-small{
				
			}
			.slider-project-home{
				.list-slider{
					height: 285px;
					.right-box{
						p{
							max-height: 38px;
							overflow: hidden;
						}
					}
					.left-box{
						display: flex;
						flex-flow: row wrap;
						justify-content: center;
					}
				}
				.text-bottom{
					left: 50px;
					right: 50px;
				}
			}
		}
		&.business{
			.box-business{
				padding-bottom: 60px;
				figure{
					height: 235px;
				}
			}
			.slider-ourbusiness-mobile{
				.slick-arrow{
					width: 10px;
					height: 18px;
				}
				.column.column-33,.column.column-34{
					flex:0 0 50%;
					max-width: 50%;
				}
			}
		}
	}

	footer{
		overflow: hidden;
		.center-footer{
			.column{
				&:nth-child(2){
					display: none;
				}
				&.column-12{
					flex: 1 0 33%;
					max-width: 33%;
				}
				&.column-25{
					flex: 1 0 33%;
					max-width: 33%;
				}
				&.images-footer{
					.column{
						display: block;
					}
				}
			}
		}
		.bottom-footer{
			small{
				font-size: 1.2rem;
			}
		}
	}
	
}