.section-about{
	position: relative;
	.images-absolute-right{
		@include position(absolute,0 0 0 null);
		z-index: -1;
		&:before{
			content:'';
			position: absolute; left: -2px; top: 0; bottom: 0;
			width: 20%; height: 100%;
			background: rgba(255,255,255,1);
			background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)));
			background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			background: -o-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
		}
	}
	.images-absolute-left{
		@include position(absolute,0 null 0 0);	
	}
	.wrapper{
		display: flex;
		align-items: center;
		min-height: 500px;
	}
}
.section-center{
	position: relative;
	.wrapper{
		min-height: 400px;
		display: flex;
		align-items: center;
		padding-top: 60px;	
		&.row-wrap{
			flex-flow: row wrap;
			justify-content: space-between;
		}
		&.nopadding{
			padding: 0;
		}
	}
	.text-wrapper{
		h5{ 
			color: $color-initial; 
			padding: 0 150px;
			margin-bottom: 25px;
		}
		p{ 
			color:#A8A9DD; 
			line-height: 3.0rem;
		}
	}
}
.milestone-years{
	.years{
		span{
			font-size: 2.4rem;
			display: block;
			color: $color-primary;
			font-weight: bold;
			text-align: center;
		}
	}
	.list-miles{
		padding-bottom: 30px;
		position: relative;
		padding-left: 25px;
		box-sizing: border-box;
		&.row{ margin-left: 0px; }
		&:before{
			content:'';
			height: 100%;
			width: 3px;
			background: #EBEBEB;
			@include position(absolute,10px null 0 6.8px);
			z-index: -1;
		}
		&:after{
			content: '';
			width: 16px;
			height: 16px;
			border-radius: 100%;
			border: 3px solid #5C5DC4;
			background: #38398B;
			@include position(absolute, 10px null 0 0);
		}
		&:last-child{
			padding-bottom: 0px;
			&:before{ display:none; }
		}
		h6{
			font-size: 1.6rem;
			font-weight: bold;
			color: #111111;
		}
		p{
			font-size: 1.5rem;
		}
	}
}

.wrap-awards{
	.row{
		margin-bottom: 40px;
	}
	.column{
		position: relative;
		top: 0;
		transition: .2s all ease-out;
		figure{
			background: #E5E5E5;
			text-align: center;
			padding: 15px 0px;
			box-sizing: border-box;
			overflow: hidden;
			img{
				transition: .2s all ease-out;
			}
		}
		.text-center{
			padding: 35px 68px;
			box-sizing: border-box;
			background: $color-initial;
		}
		.inner{
			border-radius: 20px;
			overflow: hidden;
			height: 100%;
			box-shadow: 0 0 18px -15px #000;
		}
		h6{
			color: #111;
			font-weight: 600;
			font-size: 1.6rem;
		}
		.button{
			font-size: 1.5rem;
			position: absolute;
			bottom: 15px;
			left: 0px;
			right: 0px;
			margin: 0 auto;
		}
		&:hover{
			top:2px;
			.inner{
				box-shadow: 0 0 20px -14px #000;
			}
			figure{
				img{
					/*transform: scale(1.03);*/
				}
			}
		}
		.data-popup{
			display: none;
		}

	}
}