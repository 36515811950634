@media (min-width: 0px) and (max-width: 1279px) {
	html{
		font-size: 9px;
	}
	.navigasi-banner{
		overflow: auto;
		.wrapper{
			width:900px;
			margin: 0 auto;
			ul{
				width: 100%;
				justify-content: flex-start;
			}
		}
	}
	header{
		height: 65px;
		&:before{
			z-index:1;
		}
		.wrapper{
			margin: 0 70px;
		}
		.logo{
			width: 200px;
			padding: 0px 39px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 1;
		}
		.right-header{
			position: absolute;
			right: 0; 
			left: 0;
			background: #fff;
			top: 100%;
			display: block;
			height: calc(50vh + 85px);
			border-top: 1px solid #F2F2F2;
			overflow: auto;
			border-radius: 0 0 25px 25px;
			box-shadow: 0 5px 20px -18px #000;
			box-sizing: border-box;
			padding: 10px 40px 40px;
			display: none;
			.wrap-small{
				flex-flow: row wrap;
				padding: 24px 0px 0px;
				border-top: 1px solid #F2F2F2;
			}
			.box-lang{
				margin-left: auto;
				margin-right: 0px;
				margin-bottom: 10px;
			}
			.box-search{
				flex: 1 0 100%;
				.ic-search{
					display: none;
					&.floating{ 
						display: block !important; 
						position: absolute;
						right: 15px; top: 0; bottom: 0;
						margin: auto 0;
						width: 26px;
						height: 27px;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					
				}
				.input-search{
					position: relative;
					height: auto;
					width: 100%;
					top: auto; right: auto;
					form{ margin-bottom: 0px; }
					input[type='text']{ 
						height: 70px; padding-right: 50px; font-size: 16px; border-color: #E3E3E3;
						&:focus{
							border-color:$color-primary;
						}
					}
				}
			}
			nav{
				margin-right: 0px;
				max-height: 250px;
				padding-bottom: 10px;
				&:after{ display:none; }
				ul{
					overflow: auto;
					max-height: 250px;
					>li{
						display: block;
						text-align: right;
						margin: 10px 0px;
						padding-right: 20px;
						box-sizing: border-box;
						transition: none;
						>a{
							color: #555555;
						}
						&.have-child{
							position: relative;
							&:before{
								content:'+';
								position: absolute;
								right: 0px;
								top: 0px;
								bottom: 0px;
								color: #5C5DC4;
								font-size: 18px;
								font-weight: bold;
								z-index: 1;
								line-height: 20px;
							}
							ul{ 
								position: relative;
								opacity: 1;
								visibility: visible;
								top: auto;
								left: auto; right: auto; width: auto;
								padding:10px 0px;
								display: none;

								&:before,&:after{ display:none; }
								li{
									margin: 5px 0px;
									a{ color: #555; }
								}
							}
							&.drop{
								&:before{
									content:'-';
								}
							}
						}
					}
				}
			}
		}
		&.float,&.nobanner{
			.burgermenu{
				span{
					background: #111;
				}
			}
		}
		&.burgeractive{
			&:before{
				height:100%;
			}
		}
		.burgermenu{
			display: block;
			width: 40px;
			height: 25px;
			position: relative;
			display: flex;
			flex-flow: column wrap;
			justify-content: space-between;
			cursor: pointer;
			position: relative;
			z-index: 1;
			span{
				background: #fff;
				height: 3px;
				width: 100%;
				border-radius: 20px;
				transition: .2s all ease-out;
			}
			&.close{
				span{
					background: #464646; width: 100%;
					height: 3px;
					&:nth-child(1){
						transform: rotate(45deg);
						margin-top: auto;
						position: relative;
						top: 3px;
					}
					&:nth-child(2){
						display: none;
					}
					&:nth-child(3){
						transform: rotate(-45deg);
						margin-bottom: auto;
					}
				}
			}
		}
		.overlay{
			position: fixed; top: 0; left: 0; right: 0; bottom: 0; 
			z-index: -1; background: rgba(0,0,0,0.8); display: none;
		}
	}
	.banner-content{
		height: 400px;
		.text-caption{
			h2{ font-size: 2.8rem; }
			h5{ font-size: 1.6rem; }
		}
	}
	.tag{
		font-size: 1.4rem;
	}
	.content{
		.img-absolute-1,.img-absolute-2{ display: none; }
		.wrapper{
			margin: 0 70px;
		}
	}
	.wrap-pop{
		.pop-inner{
			.box-pop{
				margin: 8% auto;
			}
		}
	}
	.text-wrapper{
		iframe{
			min-height: 350px;
		}
	}

}
@media (min-width: 0px) and (max-width: 1023px) {
	h5{ font-size: 1.7rem; }
	header{
		.right-header{
			.wrap-small{
				padding: 9px 0 0px;
				position: relative;
				bottom: auto; top: auto; right: auto; left: auto;
				background: #fff;
			}
		}
	}
	.navigasi-banner{
		.wrapper{
			margin: 0 70px;
			width: 700px;
			ul{
				justify-content: flex-start;
			}
		}
	}
	.tag{
		line-height: 40px;
	}
	.content{
		.wrapper{
			margin: 0 45px;
			width: auto;
		}
	}
	.banner-content{
		height: 300px;
		overflow: hidden;
	}
	.list-grid{
		.row{
			.column{ margin-bottom: 0px; }
			.inner{
				height: 290px;
				.text-bottom{
					left: 60px;
					right: 60px;
					bottom: 40px;
					h5{
						font-size: 1.7rem;
					}
				}
			}
		}
	}
	.wrap-news{
		figure{
			height: 200px;
		}
		p{
			margin-bottom: 15px;
			font-size: 1.3rem;
		}
	}
	.title-sort-by{
		.input-search{
			input[type='text']{
				width: 150px;
			}
			.btn-search{
				padding: 0 20px;
			}
		}
	}
	.result{
		padding-top: 20px;
		h4{
			font-size: 1.9rem;
		}
	}
	.list-result{
		.list{
			h6{
				font-size: 1.6rem;
			}
		}
	}
	.page100vh{
		.text{
			max-width: none;
			left: 185px;
			right: 185px;
			margin: 0 auto;
			width: auto;
			text-align: center;
			h1{ font-size: 12rem; }
			h2{ font-size: 6.3rem; }
			p{ 
				padding: 0px; 
				line-height: 2.6rem;
			}
		}
	}
}
.link-back { display: inline-block; font-size: 18px; font-weight: bold; margin-top: 0; }