@media (min-width: 0px) and (max-width: 1279px) {
	
	.section-about{
		.wrapper{
			min-height: 450px;
		}
		.images-absolute-right{
			width: 40%;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: left;
			}
		}
		.images-absolute-left{
			width: 40%;
			&:before{
				content:'';
				position: absolute; right: 0; top: 0; bottom: 0;
				width: 20%; height: 100%;
				background: rgba(255,255,255,0);
				background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
				background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.milestone-years{
		.years{
			span{
				font-size: 1.9rem;
				text-align: left;
			}
		}
		.list-miles{
			&.row{
				.column{
					&.column-15{ 
						flex: 1 0 20%; 
						max-width: 20%;
					}
					&.column-80{
						flex: 1 0 75%;
						max-width: 75%;
					}
				}
			}
		}
	}
	.wrap-awards{
		.row{
			.column{
				&.column-48{ 
					flex: 1 0 50%; 
					max-width: 50%;
				}
				.text-center {
					padding: 30px 20px 40px;
				}
			}
		}
	}
}


@media (min-width: 0px) and (max-width: 1023px) {
	.wrap-awards {
		.column {
			figure {
				padding: 15px 10px 10px;
			}
			.text-center {
				padding: 20px 12px 40px;
			}
		}
	}
}