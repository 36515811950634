/* header
----------------------------------------------------------------------------------------------*/
header{
	width: 100%;
	z-index: 99;
	height: 85px;
	@include position(fixed,0 0 null 0);
	transition: .2s all ease-out;
	&:before{
		content:'';
		@include position(absolute,0);
		width: 100%;
		height: 0px;
		background: #fff;
		z-index: -1;
		transition:.2s all ease-out;
	}
	.burgermenu{ display: none; }
	&.float,&.nobanner{
		box-shadow:  0 0 15px -13px #000;
		&:before{
			height:100%;
		}
		.right-header{
			nav{
				ul{
					li{
						a{ color: #555555; }
					}
				}
				&:after{
					background: #555555;
				}
			}
			.box-search{
				.ic-search{
					display: none;
					&.floating{
						display: block;
					}
				}
				.input-search{
					input{
						border-color: #999999;
						color: #1F1F1F;
						&:focus{
							border-color:#666666;
						}
					}
				}
			}
		}

	}
	.wrapper{
		width: auto;
		margin: 0 160px;
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: space-between;
	}
	.logo{
		padding: 14px 39px;
		box-sizing: border-box;
		background: $color-initial;
		border-radius: 0 0 20px 20px;
		align-self: flex-start;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 200px;
		max-width: 200px;
	}
	.right-header{
		display: flex;
		align-items: center;
		flex: 0 0 auto;
		margin-left: auto;
		nav{
			position: relative;
			margin-right: 30px;
			&:after{
				content:'';
				width: 1px;
				height: 18px;
				background:rgba(255,255,255,.25);
				margin: auto 0;
				@include position(absolute, 0 0 0 null); 
			}
			ul{
				margin: 0;
				> li{ 
					display: inline-block; 
					margin: 0 25px 0;
					position: relative;
					&:hover{
						a{
							color:$color-secondary;
						}
					}
					> a{
						color: $color-initial;
						font-size: 1.7rem;
						font-weight: 400;
						transition: .2s all ease-out;
						&.active{
							color:$color-secondary;
							font-weight: bold;
						}
					}
					&.have-child{
						ul{
							@include position(absolute,100% null null -60px);
							padding: 60px 40px 20px;
							box-sizing: border-box;
							width: 200px;
							margin: 0px;
							opacity: 0;
							visibility: hidden;
							transition: .2s all ease-out;
							 &:before{ 
	                            content:'';width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; 
	                            border-bottom: 10px solid #Fff; position: absolute; top:30px; left: 0; right: 0; z-index: 2;
	                            margin: 0 auto; @include boxShadow(0 0 20px -15px #000); 
	                            transition: .2s all ease-out;	
	                        } 
							li{
								margin: 0px 0 15px;
								padding-bottom: 0px;
								display: block;
								&:last-child{
									margin-bottom:0px;
								}
								a{
									color: #555555;
									font-size: 1.3rem;
									font-weight: 400;
									&:hover{
										color:#38398B;
									}
									&.active{
										font-weight: bold;
										color:#38398B;
										&:hover{
											font-weight:bold;
										}
									}
								}
							}
							&:after{
								content:'';
								@include position(absolute, 40px 0 0 0);
								background: #fff;
								z-index: -1;
								box-shadow: 0 0 18px -13px #000;
								border-radius: 10px;
							}
						}
						&:hover{
							ul{
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}
		}
		.box-lang{
			margin-right: 40px;
			opacity: 1;
			visibility: visible;
			transition: .2s all ease-out;
			.language{
				select{
					border: none;
					margin: 0;
					padding: 0;
					color:#CECECE;
					font-size: 1.5rem;
					font-weight: 300;
					height: auto;
					padding-right: 20px;
					box-sizing: border-box;
					background: url('../images/material/arr-down-gry.svg')no-repeat right;
					-webkit-appearance: none;
				}
			}
		}
		.box-search{
			position: relative;
			.ic-search{
				cursor: pointer;
				z-index: 2;
				position: relative;
				&.floating{
					display: none;
				}
			}
			.input-search{
				@include position(absolute, -8px 30px null null);
				height: 40px;
				width: 0;
				z-index: 1;
				overflow: hidden;
				input{
					width: 100%;
					height: 100%;
					padding: 10px;
					margin-bottom: 0px;
					color: $color-initial;
					font-size: 13px;
					&:focus{
						border-color:#fff;
					}
				}
				@include placeholder{
					font-style: italic;
					color: #ccc;
				}

			}
		}
		.wrap-small{
			display: flex;
			justify-content: space-between;
			align-items: center;
			&.open{
				.box-lang{
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}
	
}

/* content
----------------------------------------------------------------------------------------------*/
.nomargin{ margin: 0 !important; }
.nomargin-bottom{ margin-bottom:0 !important; }
.nopadding{ padding: 0; }
.tag{
	background: $color-primary;
	padding: 0 15px 0 45px;
	line-height: 45px;
	border-radius: 45px;
	font-weight: bold;
	text-transform: uppercase;
	position: relative;
	color: $color-initial;
	&.toll{
		&:before{
			content:'';
			@include position(absolute, 0 null 0 7%);
			margin: auto 0;
			background: url('../images/material/ic-toll-road.svg')no-repeat center;
			width: 26px;
			height: 18px;
		}
	}
	&.mining{
		&:before{
			content:'';
			@include position(absolute, 0 null 0 7%);
			margin: auto 0;
			background: url('../images/material/ic-mining.svg')no-repeat center;
			width: 20px;
			height: 18px;
			background-size: cover;
		}
	}
}
.banner-content{
	position: relative;
	height: 500px;
	.text-caption{
		@include position(absolute,50% 0 null 0);
		width: 700px;
		margin: 0 auto;
		transform: translateY(-50%);
		text-align: center;
		h5{
			margin-bottom: 5px;
			color: $color-primary;
			font-weight: bold;
			text-transform: uppercase;
		}
		h2{
			color: $color-initial;
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
	figure{
		position: relative;
		height: 100%;
		width: 100%;
		&:after{
			content:'';
			@include position(absolute,0 0 null 0);
			background: url('../images/material/shd-banner-content-top.png')no-repeat center;
			height: 165px;
			background-size: cover;
		}
		&:before{
			content:'';
			@include position(absolute,0);
			background: url('../images/material/shd-banner-content.png')no-repeat center;
			background-size: cover;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.breadcrumb{
	ul{
		margin-bottom: 0px;
		list-style: none;
		li{
			display: inline-block;
			margin-bottom: 0px;
			margin-right: 5px;
			padding-right: 10px;
			position: relative;
			a{
				color: #BABABA;
				font-size: 1.4rem;
			}
			&:before{
				content:'/';
				@include position(absolute,0 0 0 null);
				color: #BABABA;
			}
			&:last-child{
				margin-right: 0px;
				a{
					color: $color-initial;
					font-weight: bold;
				}
				&:before{
					display:none;
				}
			}
		}
	}
}
.navigasi-banner{
	@include position(absolute,null 0 0 0);
	margin: 0 auto;
	text-align: center;
	width: 800px;
	.wrapper{
		ul{
			width: auto;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			list-style: none;
			margin-bottom: 0px;
			li{
				margin: 0 15px;
				padding-bottom: 35px;
				position: relative;
				text-align: center;
				white-space: nowrap;
				&:before{
					content:'';
					@include position(absolute,null 0 0 0);
					width: 0;
					height: 4px;
					background: #4CB92B;
					border-radius: 20px;
					transition: .2s all ease-out;
					margin: 0 auto;
				}
				a{
					color: #C3C3C3;
					font-size: 1.6rem;
					transition:.2s all ease-out;
					text-transform: capitalize;
				}
				&.active{
					&:before{
						width:100%;
					}
					a{
						color: $color-initial;
						font-weight: 600;
					}
					&:hover{
						a{ font-weight: 600; }
					}
				}
				&:hover{
					&:before{
						width:100%;
					}
					a{ font-weight: 200; color: $color-initial; }
				}
			}
		}
	}
}
.content{
	position: relative;
	padding: 60px 0px 120px;
	&.padding-topmore{
		padding-top: 100px;
	}
	&.nopadding{
		padding: 0px;
	}
	.wrapper{
		width: 865px;
		margin: 0 auto;
	}
	.img-absolute-1{
		@include position(absolute,0 null null 0);
		&.detail{
			@include position(absolute,85px null null 0);
		}
	}
	.img-absolute-2{
		@include position(absolute,null 0 -10px null);
	}
}
.text-wrapper{
	h5{
		font-weight: bold;
		color: #111111;
	}
	p{
		color: #555555;
		line-height: 3rem;
		font-weight: 400;
	}
	ol,ul{
		li{
			color: #555555;
			font-size: 1.6rem;
			font-weight: 400;
		}
	}
	ul{
		list-style: none;
		li{
			position: relative;
			&:before{
				content: "";
				color: #5C5DC4;
				font-weight: bold;
				display: inline-block; 
				width: 8px;
				height: 8px;
				background: #5C5DC4;
				border-radius: 100%;
				margin-right:10px;
				position: relative;
			}
		}
	}
	iframe{
		width: 100%;
		min-height: 500px;
	}
}
.table-middle{
	overflow-x: auto;
	margin-bottom: 30px;
	/* width */
	&::-webkit-scrollbar {
		height: 7px;
	}
	
	/* Track */
	&::-webkit-scrollbar-track {
		background: rgba(0,0,0,0.05);
		border-radius: 7px;
	}
	
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,0.4);
		border-radius: 7px;
	}
	
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: rgba(0,0,0,0.8);
	}


	table{
		max-width: 100%;
		margin-bottom: 0;
	}
}
.bg-gradient{
	&:before{
		content:'';
		@include position(absolute,0 0 null 0);
		height: 400px;
		background: rgba(56,58,139,1);
		background: -moz-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(56,58,139,1)), color-stop(100%, rgba(48,29,100,1)));
		background: -webkit-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
		background: -o-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
		background: -ms-linear-gradient(top, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
		background: linear-gradient(to bottom, rgba(56,58,139,1) 0%, rgba(48,29,100,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#383a8b', endColorstr='#301d64', GradientType=0 );
		z-index: -1;
	}
}
.text-center{
	text-align: center;
	margin: 0 auto;
}
.images-content{
	margin-bottom: 30px;
	width: 100%;
	figure{
		border-radius: 30px;
		overflow: hidden;
		height: 400px;
	}
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.pagging{
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 60px 0 0px;
	a{
		font-size: 15px;
		color: #777777;
		font-weight: 500;
		padding: 0 15px;
		box-sizing: border-box;
		&.arr{
			@include position(absolute,0 null 0 0);
			transform: rotate(90deg);
			span{
				background: url('../images/material/ic-arrows-small.svg')no-repeat center;
				width: 16px;
				height: 10px;
				display: inline-block;
			}
			&.next{
				@include position(absolute,0 0 0 auto);
				transform: rotate(-90deg);
			}
		}
		&.active{
			font-weight: bold;
			color: $color-primary;
			&:hover{
				font-weight:bold;
			}
		}
		&:hover{
			font-weight: 400;
			color: $color-primary;
		}
	}
}
.title-sort-by{
	margin-bottom: 30px;
	h5{
		margin-bottom: 0px;
	}
	h6{
		margin-bottom: 0px;
		margin-right: 20px;
	}
	.select,.search{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.input-select{
		select{
			margin-bottom: 0px;
			width: 220px;
			border-color: #D9D9D9;
			color: #B4B4B4;
			font-size: 1.5rem;	
			padding: 0 40px 0 20px;
			box-sizing: border-box;
			border-radius: 10px;
			height: 40px;
			display: block;
			background: url('../images/material/arr-down-gry.svg')no-repeat 90%;
			-webkit-appearance: none;
			
		}
		select::-ms-expand {
			display: none;
		}
	}
	.input-search{
		input[type='text']{
			width: 220px;
			border: 1px solid #D9D9D9;
			border-radius: 10px;
			margin-bottom: 0px;
			height: 40px;
			padding: 15px 35px 15px 15px;
			// background: url('../images/material/ic-search-gry.svg')no-repeat 90% center;
		}
		form, .btn-search{ margin-bottom: 0px;  }
		.btn-search{
			line-height: 4rem;
			img{
				vertical-align: middle;
			}
		}
	}
}
.title-with-date{
	display: flex;
	align-items: flex-start;
	margin-bottom: 15px;
	.date-bullet{
		background: #282979;
		padding: 10px;
		box-sizing: border-box;
		border-radius: 100%;
		color: #fff;
		text-align: center;
		flex: 1 0 50px;
		max-width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: row wrap;
		small{ 
			display: block;
			font-size: 14px;
			font-weight: bold;
			line-height: 1px;
		}
		span{
			line-height: 1px;
			font-size: 14px;
			font-weight: bold;
			display: block;
		}
	}
	h6{
		margin-bottom: 0px;
		font-size: 1.6rem;
		font-weight: bold;
		margin-left: 10px;
		color: #111;
		align-self: center;
		flex: 1 0 calc(88% - 10px);
		max-width: calc(88% - 10px);
	}
}
.wrap-news{
	margin: 40px 0px;
	p{
		font-size: 15px;
		color: #555555;
		font-weight: 400;
	}
	figure{
		position: relative;
		border-radius: 10px 0 0 10px;
		overflow: hidden;
		width: 100%;
		height: 270px;
		position: relative;
		&:after{
			content:'';
			@include position(absolute,0);
			background: url(../images/material/shd-news.png)no-repeat center;
			background-size: cover;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.column{ 
		margin-bottom: 0px; 
		padding: 0px;
	}
	.row{
		margin-bottom: 20px;
		box-shadow: 0 0 15px -12px #000;
		padding-right: 50px;
		margin-left: 0px;
		box-sizing: border-box;
		width: 100%;
		position: relative;
		top: 0;
		transition: .2s all ease-out;
		&:hover{
			top:1px;
			box-shadow: 0 8px 15px -12px #000;

		}
	}
}
.title-detail{
	margin: 50px 0 30px;
	.date{
		display: block;
		margin-bottom: 5px;
		color: #38398B;
		font-weight: bold;
	}
}
.career-accordion{
	min-height: 400px;
	.list-accordion{
		margin-bottom: 20px;
		padding: 30px 50px;
		box-sizing: border-box;
		border: 2px solid #EAEAEA;
		border-radius: 10px;
		&.act{
			.btn-acc{
				display: none;
				&.close{
					display: inline-block;
				}
			}
		}
	}

	.btn-acc{
		font-size: 13px;
		font-weight: bold;
		color: #5C5DC4;
		text-transform: uppercase;
		&.close{
			display: none;
		}
	}

	.grid-accordion{
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		h6{
			font-size: 1.9rem;
			font-weight: 500;
			color: #333333;
			margin-bottom: 0px;
		}
		.lok{
			padding-left: 15px;
			position: relative;
			display: inline-block;
			font-size: 1.3rem;
			font-weight: bold;
			text-transform: uppercase;
			color: #999999;
			&:before{
				content:'';
				@include position(absolute,0 null 0 0);
				background: url('../images/material/ic-pin.png')no-repeat center;
				width: 10px;
				height: 13px;
				margin: auto 0px;
			}
		}
		.left-box{
			flex: 0 0 70%;
			max-width: 70%;
		}
		.right-box{
			display: flex;
			align-items: center;
			flex: 0 0 20%;
			justify-content: flex-end;
			max-width: 20%;
			figure{
				margin-left: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 30px;
				max-width: 30px;
			}
		}
	}
	.content-acc{
		padding-top: 20px;
		display: none;
		.button{
			display: block;
		}
	}
}
.result{
	padding-top: 60px;
	margin-bottom: 40px;
	h4{
		font-size: 2.8rem;
		font-weight: bold;
		color: #38398B;
		margin-bottom: 5px;
	}
	p{
		font-size: 1.8rem;
	}
}
.list-result{
	.list{
		margin-bottom: 40px;
		h6{
			font-weight: bold;
			font-size: 1.9rem;
			color: #111;
			margin-bottom: 10px;
		}
		p{
			font-size: 1.6rem;
			margin-bottom: 10px;
			font-weight: 400;
		}
		a{
			font-size: 1.5rem;
			font-weight: bold;
		}
	}
}
.page100vh{
	height: 100vh;
	overflow: hidden;
	position: relative;
	&.noshadow{
		&:before{ display:none; }
	}
	&:before{
		content:'';
		@include position(absolute,0 0 null 0);
		background: url(../images/material/shd-misc-top.png)no-repeat top;
		background-size: cover;
		width: 100%;
		height: 165px;
		z-index: 2;
	}
	&:after{
		content: '';
		@include position(absolute,0);
		background: url(../images/material/shd-misc-bottom.png)no-repeat bottom;
		background-size: cover;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.bg{
		@include position(absolute,0);
		width: 100%;
		height: 100%;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.text{
		text-align: center;
		@include position(absolute,50% 0 null 0);
		transform: translateY(-50%);
		color: #fff;
		max-width: 786px;
		margin: 0 auto;
		z-index: 3;
		h1{
			font-size: 16rem;
			font-weight: bold;
		}
		h5{
			font-weight: 600;
		}
		p{
			line-height: 30px;
			padding: 0 100px;
		}
		h2{
			font-size: 8rem;
			font-weight: 600;
		}
	}
}

/* popup style
----------------------------------------------------------------------------------------------*/
.wrap-pop{
    position: fixed; height: 100%; top: 0; left: 0; right: 0; bottom: 0; width: 100%; background:rgba(0,0,0,.9);
    z-index: 9999999; overflow-y: auto; display: none;
    .pop-inner{
        position: absolute; display: block; width:100%;
        max-width: 768px; margin: 50px auto 30px; left: 0; right: 0;
        .overlay-pop{
            position: absolute; left: 0; bottom: 0; right: 0; top: 0;  margin: auto;
        }
         .close{
            position: absolute; right: 3rem; top: 3rem; width:35px; height: 35px; cursor: pointer;
            z-index: 8; cursor: pointer;@include borderRadius(100%);
            &:after,&:before{
                content:''; width: 30px; height: 4px; background:#888888; display: block; @include transform(rotate(45deg)); top: 15px;
                position: absolute; left: 0; right: 0; margin: auto; @include borderRadius(20px);
                @include transition(.3s all ease-out);
            }
            &:before{  @include transform(rotate(-45deg));   }
            &:hover{
                &:after{ @include transform(rotate(-45deg)); }
                &:before{ @include transform(rotate(-135deg)); }
            }
        }
        .content-pop{
           
            z-index: 8; 
            background: #fff; 
            border-radius: 30px;
            overflow: hidden;
            .img{
            	text-align: center;
            	background: #E5E5E5;
            	padding: 40px 0px;
            	box-sizing: border-box;
            }
            .text{
            	padding: 40px 20px 10px;
            	box-sizing: border-box;
            	text-align: center;
            	p{
            		color: #111;
            		font-weight: 600;
            		font-size: 16px;
            	}
            }
        }
       
    }
    #alert{
    	 .box-pop{
    		width: 660px; 
    		margin: 5% auto; 
    	    position: relative; 
    	    text-align: center;	 
        }
    	
    }

}

/* footer
----------------------------------------------------------------------------------------------*/
footer{
	position: relative;
	padding-top: 37px;
	overflow: hidden;
	&:before{
		content:'';
		@include position(absolute,0 0 null 0);
		background: url('../images/material/logo-footer.svg')no-repeat top;
		height: 37px;
		background-size: cover;
	}
	.wrapper{
		width: 1197px;
		margin: 0 auto;
	}
	.center-footer{
		background: #151515;
		padding: 90px 0 65px;
		color: #BEBEBE;
		h6{
			color: #D9D9D9;
			font-size: 15px;
			font-weight: bold;
			text-transform: capitalize;
			margin-bottom: 10px;
		}
		p{
			margin-bottom: 5px;
			font-size: 13px;
		}
		span{
			display: block;
			font-size: 13px;
			a{
				color: #BEBEBE;
				font-size: 13px;
				display: inline-block;
			}
		}
		a{
			display: block;
			font-size: 13px;
			color: #BEBEBE;
		}
		.images-footer{
			.column{
				text-align: center;
			}
		}
		.navigasi{
			a{
				&:hover{ color:$color-secondary; }
			}
		}
	}
	.bottom-footer{
		padding: 30px 0px 34px;
		box-sizing: border-box;
		background: #151515;
		border-top: 1px solid #C6C6C6;
		small{
			font-size: 14px;
			color: #C6C6C6;
			a{
				color: #C6C6C6;
			}
		}
	}
}