@media (min-width: 0px) and (max-width: 767px) {
	.slider-hang{
		.slider-big{
			height: 160px;
		}
		.slider-thumbnail{
			left: 60px;
			right: 60px;
			.list-slider{
				margin: 0px 5px;
			}
			.slick-arrow{
				width: 9px;
				height: 16px;
				left: auto;
				bottom: auto;
				right: -30px;
				top: 15%;
				&.slick-prev{
					right: auto;
					left: -30px;
				}
			}
		}
	}
	.box-column-categ{
		.row{
			flex-flow: row wrap;
			.column{
				&.column-33{
					flex: 1 0 50%;
					max-width: 50%;
				}
			}
		}
		&.highlight{
			.column.column-33{
				&:nth-child(3){
					display: none;
				}
			}
		}
	}
	.slider-category{
		.list-slider{
			margin-right: 25px;
			figure{
				width: 35px;

			}
			span{
				font-size: 1.4rem;
			}
		}
	}
	.wrap-detail-category{
		.right-box{
			display: none;
		}
		.row .column.column-49{
			&.left-box{
				flex: 1 0 100%;
				max-width: 100%;
				.row{
					flex-flow: row wrap;
				}
			}
		}
	}
	.accordion{
		padding: 40px 0px;
		.wrap-accordion{
			.list-acc{
				padding: 25px 30px;
				.grid-accordion{
					h6{
						font-size: 1.6rem;
					}
				}
			}
		}
	}
	.wrap-detail-category{
		.es-detail{
			flex-direction: row;
			flex-wrap: wrap;
			.button{
				margin-top: 10px;;
			}
			.column{
				&.column-49{
					max-width: 100%;
					flex-basis: 100%;
					margin-bottom: 20px;
				}
			}
		}
	}
	.other-produk{
		.slider-other-produk, .list-other-produk{
			.list{
				.spek{
					padding: 0;
					p{
						margin-bottom: 2px;
						&.title{
							margin-bottom: 5px;
						}
					}
					.row{
						flex-direction: row;
						flex-wrap: wrap;
						margin-bottom: 5px;
					}
					.column{
						max-width: 100%;
						flex-basis: 100%;
					}
				}
			}
		}
	}
}