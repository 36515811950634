@media (min-width: 0px) and (max-width: 1279px) {

	.slider-hang {
		.slider-big{ height: 300px; }
	}
}

@media (min-width: 0px) and (max-width: 1023px) {
	.wrap-detail-category{
		.button{ 
			line-height: 4rem; 
			font-size: 1.5rem;
		}
		h4{
			font-size: 1.7rem;
		}
		.es-detail{
			h4{
				font-size: 2rem;
				margin-bottom: 10px;
			}
		}
	}
	.slider-hang {
		.slider-big{ height: 270px; }
	}

}
@media (min-width: 0px) and (max-width: 991px) {
	.other-produk .list-other-produk .list { width: calc(100% / 2 - 30px); }
}